import "../styles/globals.css";
import { ToastProvider } from "@helpers/toast";
import type { AppProps } from "next/app";
import {
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  SolletExtensionWalletAdapter,
  SolletWalletAdapter,
  TorusWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import config from "@helpers/config";
import { useMemo } from "react";

import { ThemeProvider } from "@helpers/theme";

function MyApp({ Component, pageProps }: AppProps) {
  const network = config.CLUSTER_NETWORK;

  const endpoint = config.QUICKNODE_CLUSTER_URL;
  console.log(endpoint);

  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SlopeWalletAdapter(),
      new SolflareWalletAdapter({ network }),
      new TorusWalletAdapter(),
      new LedgerWalletAdapter(),
      new SolletWalletAdapter({ network }),
      new SolletExtensionWalletAdapter({ network }),
    ],
    [network]
  );

  return (
    <ThemeProvider>
      <ToastProvider>
        <ConnectionProvider endpoint={endpoint}>
          <WalletProvider wallets={wallets} autoConnect>
            <Component {...pageProps} />
          </WalletProvider>
        </ConnectionProvider>
      </ToastProvider>
    </ThemeProvider>
  );
}

export default MyApp;
