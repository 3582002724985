import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";

export default {
  FARM_PROGRAM_ID: process.env.NEXT_PUBLIC_FARM_PROGRAM_ID as string,
  CLUSTER_NETWORK: process.env
    .NEXT_PUBLIC_CLUSTER_NETWORK as string as WalletAdapterNetwork,
  CLUSTER_URL: process.env.NEXT_PUBLIC_CLUSTER_URL as string,
  QUICKNODE_CLUSTER_URL: process.env.NEXT_PUBLIC_QUICKNODE_CLUSTER_URL as string,
  CANDY_MACHINE: process.env
    .NEXT_PUBLIC_CANDY_MACHINE as string,
  FARM_ADDRESS: process.env.NEXT_PUBLIC_FARM_ADDRESS as string
};
