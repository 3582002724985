import { createContext, FunctionComponent, useState } from "react";

interface ButtonProperties {
  bgColor: string;
  hoverBgColor: string;
  disabledBgColor: string;

  textColor: string;
  hoverTextColor: string;
  disabledTextColor: string;
}

export interface Theme {
  buttons: {
    primary: ButtonProperties;
    secondary: ButtonProperties;
  };
  body: {
    bgColor: string;
    textColor: string;
  };
}

export const base: Theme = {
  body: {
    bgColor: "#fff",
    textColor: "#000",
  },
  buttons: {
    primary: {
      textColor: "#eee",
    },
    secondary: {
      textColor: "#eee",
    },
  } as any,
};

/**
 * A function to take a string written in dot notation style, and use it to
 * find a nested object property inside of an object.
 *
 * Useful in a plugin or module that accepts a JSON array of objects, but
 * you want to let the user specify where to find various bits of data
 * inside of each custom object instead of forcing a standardized
 * property list.
 *
 * @param String nested A dot notation style parameter reference (ie "urls.small")
 * @param Object object (optional) The object to search
 *
 * @return the value of the property in question
 */
export function getThemeValue(propertyName: string, theme: object): string {
  let i;
  let property = theme;
  let parts = propertyName.split(".");
  let length = parts.length;

  for (i = 0; i < length; i++) {
    property = (property as any)[parts[i]];
  }

  return property as any;
}

export const ThemeCtx = createContext<{
  theme: Theme;
  setTheme: (theme: Theme) => void;
  getFromTheme: (property: string) => string;
}>({
  theme: base,
  setTheme: () => {},
  getFromTheme() {
    return "";
  },
});

export const ThemeProvider: FunctionComponent<{
  defaultTheme?: Theme;
}> = ({ children, defaultTheme = base }) => {
  const [theme, setTheme] = useState(defaultTheme);

  return (
    <ThemeCtx.Provider
      value={{
        theme,
        setTheme,
        getFromTheme(property: string) {
          return getThemeValue(property, theme);
        },
      }}
    >
      {children}
    </ThemeCtx.Provider>
  );
};
