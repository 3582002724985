import cx from "classnames";
import {
  createContext,
  FunctionComponent,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
} from "react";
import { CheckCircleIcon } from "@heroicons/react/outline";

interface Toast {
  id?: number;
  message: string;
  timeout?: number;
  variant?: "success" | "error" | "info";
}

interface ToastContextValue {
  toasts: Toast[];
  setToasts: Dispatch<SetStateAction<Toast[]>>;
}

export const ToastContext = createContext<ToastContextValue>({
  toasts: [],
  setToasts: () => {},
});

export const useToasts = () => {
  const { setToasts } = useContext(ToastContext);

  return {
    toast: function toast(toast: Toast) {
      const id = Date.now() + Math.random() * 1000;

      const newToast: Toast = {
        id,
        timeout: 5000,
        variant: "success",
        ...toast,
      };

      setToasts((prevToasts) => [...prevToasts, newToast]);

      setTimeout(() => {
        setToasts((currentToasts) =>
          currentToasts.filter((toast) => toast.id !== newToast?.id)
        );
      }, newToast.timeout);
    },
  };
};

const ToastMessage: FunctionComponent<{
  toast?: Toast;
}> = ({ toast }) => {
  const iconClasses = "w-6 h-6 text-white";

  return (
    <div
      className={cx(
        "w-full lg:w-auto rounded-lg text-white mt-6 p-4 shadow-xl flex items-center",
        {
          "bg-secondary-300": toast?.variant === "success",
          "bg-secondary-200": toast?.variant === "error",
        }
      )}
    >
      {toast?.variant === "success" ? (
        <CheckCircleIcon className={iconClasses} />
      ) : null}
      {/* {toast?.variant === 'error' ? <XIcon className={iconClasses} /> : null} */}

      <p className="text-white font-body text-sm ml-4">{toast?.message}</p>
    </div>
  );
};

export const ToastProvider: FunctionComponent = ({ children }) => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  return (
    <ToastContext.Provider
      value={{
        toasts,
        setToasts,
      }}
    >
      <div className="flex flex-col gap-y-4 w-full fixed z-40 top-0 items-center">
        {toasts?.map((toast) => (
          <ToastMessage key={toast.id} toast={toast} />
        ))}
      </div>
      {children}
    </ToastContext.Provider>
  );
};
